<template>
<div class="btn-group-container">
  <div class="d-flex flex-column-reverse mt-3 btn-group-wrapper" :class="`${customClasses}`">
    <button
      v-if="this.$route.name !== 'navigation'"
      v-on:click="onCancel"
      class="shadow-none cancel-btn action-btn left-arrow"
      :disabled="disableBack"
    >
      {{ cancelText }}
    </button>
    <div v-if="!this.exceptionList.includes(this.$route.name)" class="save-btn-group mr-2" :class="(disableSave || listed) ? 'skip-btn-cursor' : ''">
      <button
        v-on:click="onSave"
        class="shadow-none action-btn right-arrow"
        :class="`${notValid ? 'not-valid-save-btn' : 'save-btn'}`"
        :disabled="(disableSave || listed)"
      >
        {{ saveText }}
      </button>
    </div>
    <div 
      class="submit-btn-group"
      :style="this.$route.name === 'navigation' ? 'width: 50%' : 'width: 100%'"
      :class="this.$route.name === 'navigation' ? 'ml-2' : ''"
    >
      <button
        v-on:click="onSubmit"
        :class="`
          text-white submit-btn action-btn right-arrow 
          ${disableSubmit || isHavingUploadIssue || notValid ? 'disabled' : ''}
        `"
        :style="`${validateMandatory || isHavingUploadIssue ? 'background-color: #CACACA !important; border-color: #CACACA !important;' : ''}`"
        :disabled="disableOnUpload || isHavingUploadIssue"
      >
        <b-spinner v-if="isLoadingApi" label="Loading..." class="btn-loading"></b-spinner>
        <template v-else>
          {{ submitText }}
        </template>
      </button>
    </div>
  </div>
</div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "BtnGroup",
  props: {
    saveText: {
      type: String,
      default: "Save draft",
    },
    cancelText: {
      type: String,
      default: "",
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    saveBtnDisable: {
      type: Boolean,
      default: false,
    },
    isLoadingApi: {
      type: Boolean,
      default: false,
    },
    customClasses: {
      type: String,
      default: '',
    },
    save: String,
    back: String,
    submit: String,
    disableSave: {
      type: Boolean,
      default: false,
    },
    disableBack: Boolean,
    disableSubmit: Boolean,
    isMobile: Boolean,
    validateMandatory: Boolean,
    query: {
      type: Object,
      required: false,
    },
    disableOnUpload: {
      type: Boolean,
      default: false,
    },
    isHavingUploadIssue: {
      type: Boolean,
      default: false,
    },
    notValid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      curStep: (state) => state.common.step,
      listed: (state) => state.authenticateSeller.listed,
    }),
    exceptionList() {
      return ['vehicle-info', 'condition-info', 'photo-upload', 'photo', 'contact'];
    },
  },
  methods: {
    onSave() {
      this.$emit("onSave");
      if (this.save) {
        this.query ? this.$router.push({ name: this.save, query: this.query }) : this.$router.push({ name: this.save });
      }
    },
    onCancel() {
      this.$emit("onCancel");
      if (this.back) {
        this.query ? this.$router.push({ name: this.back, query: this.query }) : this.$router.push({ name: this.back });
      }
    },
    onSubmit() {
      this.$emit("onSubmit");
      if (this.submit) {
        this.query ? this.$router.push({ name: this.submit, query: this.query }) : this.$router.push({ name: this.submit });
      }
    },
  },
};
</script>
<style lang="scss">
$arrowBorderSize: 3px;
$arrowSize: 10px;
$left: 135deg;
$right: 315deg;
$buttonMaxWidth: 60px;
.btn-group-wrapper {
  background-color: white;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  padding-bottom: 15px;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  flex-direction: row !important;
  justify-content: space-between;
  z-index: 101;
  .cancel-btn {
    max-width: $buttonMaxWidth;
    margin-right: 15px;
  }
  .action-btn {
    position: relative;
    outline: none !important;
    width: 100%;
    height: 52px;
    padding: 16px 12px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
    &::before {
      content: '';
      border-bottom-style: solid;
      border-bottom-width: $arrowBorderSize;
      border-right-style: solid;
      border-right-width: $arrowBorderSize;
      position: absolute;
      top: 39%;
      height: $arrowSize;
      width: $arrowSize;
    }
    &.left-arrow {
      &::before {
        left: 17px;
        transform: rotate($left);
      }
    }
    &.right-arrow {
      &::before {
        right: 13px;
        transform: rotate($right);
      }
    }
    &.mobile-translate {
      padding-left: 13px;
      justify-content: flex-start;
    }
  }
  .cancel-btn {
    background-color: #ffffff;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
  .submit-btn {
    background-color: var(--primary-color);
    flex: 1;
    padding: 16px 32px;
    &:hover, &:active {
      background: linear-gradient(0deg, rgba(98, 0, 238, 0.08), rgba(98, 0, 238, 0.08)), var(--primary-color);
    }
    &.disabled {
      background-color: var(--secondary-color) !important;
      border: solid 1px var(--primary-color) !important;
      cursor: not-allowed;

      &:hover, &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #C4C4C4;
      }
    }
  }
  .not-valid-save-btn,
  .save-btn {
    background-color: #ffffff;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    flex: 1;
    width: 100%;
    &:disabled {
      color: #ffffff !important;
    }
  }
  .skip-btn-cursor  {
    cursor: not-allowed !important;
  }
  .not-valid-submit-btn {
    background-color: var(--secondary-color) !important;
    border: solid 1px var(--primary-color) !important;
  }
  .save-btn-group {
    width: 50%;
  }
}

@media screen and(min-width: 374px) {
  .btn-group-wrapper{
    .action-btn {
      justify-content: center;
      padding: 16px 9px;
      &.left-arrow {
        &::before {
          left: 27px;
        }
      }
    }
  }
}

@media screen and(min-width: 768px) {
  $buttonMaxWidth: 79px;
  .btn-group-wrapper {
    .action-btn {
      padding: 16px 24px;
      font-size: 16px;
      &.mobile-translate {
        justify-content: center;
      }
    }
    .cancel-btn {
      max-width: $buttonMaxWidth;
      margin-right: 20px;
    }
    .save-btn {
      justify-content: center;
    }
    .left-arrow::before {
      left: 35px !important;
    }
    .right-arrow::before {
      right: 35px !important;
    }
  }
}

@media screen and(min-width: 900px) {
  .btn-group-wrapper {
    z-index: 100001;
  }
}
@media screen and(min-width: 1200px) {
  .btn-group-wrapper {
    max-width: 1160px;
    padding-left: 55px;
    padding-right: 55px;
  }
}
</style>